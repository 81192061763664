<template>
  <div class="mx-1">
      <div class="row mb-2">
          <div class="col-12 text-right">
            <button class="btn btn-xs mr-2" @click="selected=0" :class="{'btn-danger': selected===0,'btn-secondary': selected !==0}" v-if="isAllowed('accident-incident.general.view.crew.info') && isOwnVessel()" >Crew List</button>
            <button class="btn btn-secondary btn-xs" @click="selected=1" :class="{'btn-danger': selected===1,'btn-secondary': selected !==1}" v-if="isAllowed('accident-incident.general.view.crew.exp') && isOwnVessel()">Experience Lists</button>
          </div>
      </div>
      <template v-if="selected===0">
        <div class="row">
          <div class="col-12">
            <h5  id="crewListInformationLabel">Crew List Information as of {{formattedEventDate}}</h5>
            <table class="table w-100 table-bordered table-responsive">
              <thead>
              <tr>
                <th class="align-middle text-center" scope="col" style="width: 1%;">#</th>
                <th class="align-middle text-center" scope="col" style="width: 7%;">RANK</th>
                <th class="align-middle text-center" style="width: 15%" scope="col">NAME</th>
                <th class="align-middle text-center" style="width: 7%" scope="col">IPN</th>
                <th class="align-middle text-center" style="width: 4%" scope="col">EMBARK DATE</th>
                <th class="align-middle text-center" style="width: 10%" scope="col">TIME ONBOARD AT TIME OF REPORT</th>
                <th class="align-middle text-center" style="width: 25%" scope="col">MANNING</th>
                <th class="align-middle text-center" style="width: 5%" scope="col">NATIONALITY</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(crew,index) in crews">
                <td class="align-middle text-center">{{ index + 1 }}</td>
                <td class="align-middle text-center">{{crew.rank.toUpperCase()}}</td>
                <td class="align-middle" style="white-space: nowrap">
                  <router-link :to="{name:'CrewInfo',params:{id:crew.id}}" target="_blank" v-if="isAllowed('accident-incident.general.view.crew.info-link')">
                    {{crew.last_name}}, {{crew.first_name}} {{crew.middle_name.substring(0,1).toUpperCase()}}.
                  </router-link>
                  <span v-else>
                    {{crew.last_name}}, {{crew.first_name}} {{crew.middle_name.substring(0,1).toUpperCase()}}.
                  </span>
                </td>
                <td class="align-middle text-center">{{crew.ipn.toUpperCase()}}</td>
                <td class="align-middle text-center">{{crew.embarkationDate}}</td>
                <!--                      <td class="align-middle text-center">{{crew.disembarkationDate}}</td>-->
                <td class="align-middle text-center">{{crew.duration.toUpperCase()}}</td>
                <td class="align-middle text-center" style="white-space: nowrap">{{crew.manning.toUpperCase()}}</td>
                <td class="align-middle text-center">{{crew.nationality.toUpperCase()}}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </template>
      <template v-else-if="selected===1">
        <div style="max-height:80vh; width: 100%; overflow-y: auto; overflow-x: hidden" class="mt-2">
          <template v-for="(crew,index) in crews">
            <experience-ui :crew="crew"></experience-ui>
          </template>
        </div>
      </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {KeyService} from "@/services/KeyService";
import {DateService} from "@/services/DateService";
import ExperienceUi from "@/components/crew/experience/ExperienceUi";
import {permissionMixin} from "@/mixins/permissionMixins";
export default {
  name: "VesselCrewLists",
  mixins:[permissionMixin],
  components:{'experience-ui':ExperienceUi},
  data(){
    return {
      dateToday:new Date().toISOString().split('T')[0],
      selected:0,
      crews:{},
    }
  },
  async created() {
    const params={
      onboard_from:this.dateToday,
      onboard_to:this.dateToday,
      vesselId:this.$route.params.id,
      hasImage:true,
    }

    swal.fire({
      'title':'ESTABLISHING CONNECTION TO CMS',
      allowEscapeKey:false,
      allowOutsideClick:false,
      allowEnterKey:false
    })
    swal.showLoading()
    const response = await KeyService.getCrewKeys(params)
    this.crews= response.crews
    swal.close();
  },
  computed:{
    ...mapGetters(['crewKeys']),
    formattedEventDate() {
      return DateService.dateEliteFormat(this.dateToday)
    }
  }
}
</script>

<style scoped>

</style>
